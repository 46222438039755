var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('time-sheet-loader',{attrs:{"row-num":3}}):(_vm.items.length)?_c('div',{staticClass:"emp-list"},[_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"scrollable-element emp-list-data align-th-table tableDesigns",attrs:{"items":_vm.items,"fields":_vm.fields,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"size":"36","src":data.item.user ? data.item.user.picture : '',"text":_vm._f("avatarText")(data.item.user.full_name),"variant":_vm.randomVariant(1, data.item.user)}}),_c('div',{staticClass:"emp-detail"},[_c('p',{staticClass:"emp-name"},[_vm._v(" "+_vm._s(data.item.user.name)+" "+_vm._s(data.item.user.last_name)+" ")])])],1)]}},{key:"cell(duration)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm._f("convertMinutes")(data.item.total_time)))])])]}},{key:"cell(week)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(new Date(data.item.start_date).toLocaleDateString("en-us", { weekday: "short", month: "short", day: "numeric", year: "numeric", }))+" - "+_vm._s(new Date(data.item.end_date).toLocaleDateString("en-us", { weekday: "short", month: "short", day: "numeric", year: "numeric", })))])])]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[(data.item.approval_status == 'pending')?_c('span',{class:data.item.approval_status == 'pending'
                    ? 'text-black-50'
                    : ''},[_vm._v(" "+_vm._s(data.item.approval_status)+" ")]):_vm._e(),(data.item.approval_status == 'partial_approved')?_c('span',{class:data.item.approval_status == 'pending'
                    ? 'text-black-50'
                    : ''},[_vm._v(" Partial Approved ")]):_vm._e(),(data.item.approval_status == 'approved')?_c('span',{class:data.item.approval_status == 'approved'
                    ? 'text-success'
                    : ''},[_vm._v(" "+_vm._s(data.item.approval_status)+" ")]):_vm._e(),(data.item.approval_status == 'rejected')?_c('span',{class:data.item.approval_status == 'rejected' ? 'text-danger' : ''},[_vm._v(" "+_vm._s(data.item.approval_status)+" ")]):_vm._e(),(data.item.approval_status == 'in_process')?_c('span',{class:data.item.approval_status == 'in_process'
                    ? 'text-warning'
                    : ''},[_vm._v(" Submitted ")]):_vm._e()])]}},{key:"cell(action)",fn:function(data){return [(data.item.approval_status !== 'pending')?_c('span',{staticClass:"ml-2 pl-50"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"emp-eyeicon",attrs:{"icon":"EyeIcon","size":"18","title":"View"},on:{"click":function($event){return _vm.passUserId(data.item)}}})],1):_c('span',[_vm._v(" Not Submitted ")])]}}])})],1)],1),(_vm.totalCount>0)?_c('Pagination',{attrs:{"current-page":_vm.page,"per-page":_vm.perPage,"total-count":_vm.totalCount},on:{"currentPage":function($event){return _vm.updatePage($event)}}}):_vm._e()],1):_c('div',{staticClass:"w-100"},[_c('img',{staticClass:"no-data-img",attrs:{"src":require("@/assets/images/nodatafound/nodata.svg"),"alt":"no-data"}})]),_c('div',[_c('b-modal',{staticClass:"addtask-modal rejected-modal",attrs:{"id":"updateStatus","ok-only":"","ok-title":"Accept","centered":"","size":"sm","show-ok":false},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',{staticClass:"addtask-from-title"},[_c('span',[_vm._v(" Rejected reason ")])])]},proxy:true},{key:"modal-footer",fn:function(ref){
                    var cancel = ref.cancel;
return [_c('div',{staticClass:"text-center w-100"},[_c('div',[_c('b-button',{staticClass:"btnClose",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Add Reason ")])],1)])]}}]),model:{value:(_vm.modalStatus),callback:function ($$v) {_vm.modalStatus=$$v},expression:"modalStatus"}},[_c('validation-observer',{ref:"rejectReasonRules"},[_c('b-row',{staticClass:"addtask-content"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"m-1 to-do-input auto-resize-teaxtarea",attrs:{"label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Rejected reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.rejectedReasonMsg),expression:"rejectedReasonMsg"}],ref:"textarea",attrs:{"name":"Rejected reason","placeholder":"Reason ...","rows":"5"},domProps:{"value":(_vm.rejectedReasonMsg)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.rejectedReasonMsg=$event.target.value},_vm.resizeTextarea]}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }